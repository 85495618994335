<template>
  <div class="onboard-modal">
    <div>
      <!-- Image -->
      <div class="flex justify-center">
        <ImageResize
          class="onboard-image"
          :src="data[currentSlide].image"
          :alt="data[currentSlide].title"
          type="onboard"
          :key="`onboard_slide_${currentSlide}`"
        />
      </div>

      <!-- Text (title and subtitle) -->
      <div class="flex flex-col items-center mt-n-2xl">
        <div class="onboard-text-wrapper">
          <div class="text-n-3xl font-bold text-center">
            {{ data[currentSlide].title }}
          </div>
          <div class="text-n-lg mt-n-3xl">
            {{ data[currentSlide].subtext }}
          </div>
        </div>
      </div>

      <!-- Slide indicator -->
      <div class="flex justify-center mt-n-3xl">
        <div v-for="(slide, index) in data"
          :key="`slide_indicator_${index}`"
          class="slide-indicator"
         :class="{ 'bg-background-positive-dark': index === currentSlide, 'bg-border': index !== currentSlide }"
        >
        </div>
      </div>
    </div>

    <!-- Action buttons -->
    <div class="flex justify-center mt-n-5xl">
      <ButtonV2
        v-if="!isLastSlide"
        @onClick="customClose"
        :label="$t('navigation.skip')"
        testId="btn-skip"
        version="secondary"
        size="medium"
        class="onboard-action-button"
        wide
      />
      <ButtonV2
        @onClick="nextSlide"
        :label="isLastSlide ? data[currentSlide].button_caption : $t('navigation.next')"
        testId="btn-next"
        size="medium"
        class="onboard-action-button"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import { ImageResize } from '@/components/misc';

export default {
  name: 'Onboard',
  components: {
    ButtonV2,
    ImageResize
  },

  computed: {
    isLastSlide() {
      return this.currentSlide + 1 === this.data.length;
    },
  },

  data() {
    return {
      currentSlide: 0,
    };
  },

  methods: {
    nextSlide() {
      if (this.isLastSlide) {
        this.customClose();
        this.$router.push('/sign-up').catch(()=>{});
        return;
      }
      this.currentSlide++;
      this.$store.dispatch('events/track', {
        event: 'ONBOARDING_PAGE_CHANGED',
        variables: {
          page_index: this.currentSlide,
        },
      });
    }
  },

  props: {
    customClose: Function,
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
  .onboard-modal {
    @apply p-n-4xl flex flex-col justify-between;
    width: 564px;
    max-width: 100%;
    height: 100%;
  }

  .onboard-image {
    @apply object-contain;
    width: 500px;
    height: 380px;
  }

  .onboard-text-wrapper {
    max-width: 432px;
  }

  .onboard-action-button {
    @apply mr-n-xl;
    max-width: 341px;
  }

  .slide-indicator {
    @apply rounded-full mx-n-sm;
    width: 8px;
    height: 8px;
  }
</style>
